import { getPersonName } from '@/helper/formatter'
import { supabase } from '@/supabase'
import { Person, Role, UserRole } from '@/types/extendDatabase'
import { defineStore } from 'pinia'
import { Ref, ref } from 'vue'

export const useRightsStore = defineStore('rights', () => {
  let roles: Ref<Role[]> = ref([])
  let persons: Ref<Person[]> = ref([])
  let rights: Ref<UserRole[]> = ref([])
  let personWithRights: Ref<PersonWithRights[]> = ref([])

  type PersonWithRights = {
    user_id: string
    user: string
    role: {
      id: number
      role: string
    }
    assignedGroup: { id: number; label: string }
  }

  let initDone = new Promise(async (resolve) => {
    supabase.auth.onAuthStateChange(async (event) => {
      if (event === 'SIGNED_IN') {
        await fetchPersons()
        await fetchRights()
      } else if (event === 'SIGNED_OUT') {
        persons.value = []
        rights.value = []
      }
    })

    await fetchRoles()
    await fetchPersons()
    await fetchRights()
    joinTables()
    resolve(null)
  })

  async function fetchRoles() {
    try {
      const { data, error } = await supabase.from('rights_role').select('*')
      if (data) roles.value = data
    } catch (error: any) {
      console.error(error)
    }
  }

  async function fetchPersons() {
    try {
      const { data, error } = await supabase.from('person').select('*')
      if (data) persons.value = data
    } catch (error: any) {
      console.error(error)
    }
  }

  async function fetchRights() {
    try {
      const { data, error } = await supabase.from('rights_user_role').select('*')
      if (data) rights.value = data
    } catch (error: any) {
      console.error(error)
    }
  }

  function joinTables() {
    for (const person of persons.value) {
      const userRights = rights.value.find((r) => r.user_id === person.id)!
      const role = roles.value.find((r) => r.id === userRights.role_id)! as Role
      const newEntry: PersonWithRights = {
        user_id: person.id,
        user: getPersonName(person),
        role: role,
        assignedGroup: { id: userRights.assigned_group, label: 'Alle' },
      } //TODO
      personWithRights.value.push(newEntry)
    }
  }

  async function updateRights(newEntries: PersonWithRights[]) {
    console.log('ANZAHL: ', newEntries.length)
    for (const newEntry of newEntries) {
      try {
        const entryForSupabase: Omit<UserRole, 'id'> = {
          role_id: newEntry.role.id,
          user_id: newEntry.user_id,
          assigned_group: newEntry.assignedGroup.id,
        }
        console.log(entryForSupabase)
        const { data, error } = await supabase
          .from('rights_user_role')
          .update(entryForSupabase)
          .eq('user_id', newEntry.user_id)
        // .single()
        console.log(data)
        if (error) throw error
      } catch (error: any) {
        console.error(error)
      }
    }
  }

  return { roles, persons, rights, personWithRights, initDone, fetchRoles, fetchPersons, fetchRights, updateRights }
})
